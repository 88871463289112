export enum SalesReturnType {
	UpToFive = 2,
	SixOrMore = 3,
}

export enum SalesReturnStepType {
	UpToFiveRequest = 12,
	UpToFiveProofOfDiscard = 23,
	UpToFiveDone = 14,
	MoreThanSixRequest = 17,
	MoreThanSixProofOfDiscard = 28,
	MoreThanSixDone = 19,
	MoreThanSixGoodsReturn = 21,
}

export enum RequesterType {
	Customer = 1,
	Representative = 2,
	CustomerRepresentative = 3,
}

export enum SalesReturnGoodsReturnTransportationType {
	Transportadora = 1,
	Correios = 2,
	Representante = 3,
}

export const SALES_RETURN_STEP_DESCRIPTION = {
	Request: 'Solicitação',
	Fiscal: 'Fiscal',
	Returned: 'Retorno da Solicitação',
	Processing: 'Em Processamento',
	ProofOfDiscard: 'Comprovação do Descarte do Produto',
	Done: 'Encerrado',
	GoodsReturn: 'Coleta da Mercadoria',
};
